<template>
	<button :class="computedClass" :disabled="disabled">
		<slot v-if="!loading" />
		<LoaderCircle class="mx-auto animate-spin" v-if="loading" />
	</button>
</template>

<script setup lang="ts">
import { LoaderCircle } from 'lucide-vue-next'
import { twMerge } from 'tailwind-merge'

const props = defineProps({
	variant: {
		type: String,
		default: 'white',
	},
	loading: {
		type: Boolean,
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	class: {
		type: [String, Object],
		default: '',
	},
})

const classes = {
	DEFAULT:
		'rounded-[60px] h-[60px] font-bold w-full text-lg disabled:opacity-40 disabled:cursor-not-allowed hover:bg-[#CEF26F] hover:duration-200 hover:transition-all hover:text-black disabled:pointer-events-none',
	black: 'bg-black text-white',
	white: 'bg-white ',
	outline: 'bg-transparent border-[2px] border-[#E8ECEF]',
}

const computedClass = computed(() => {
	return twMerge(classes.DEFAULT, classes[props.variant], props.class)
})
</script>
